import React from "react"
import Video from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Video"

const VideoFigure = ({
  className,
  caption,
  _uid = "7",
  video_url,
  ratio = "16by9",
  captionClass = "",
}) => {
  return (
    <figure className={className}>
      <div>
        <Video video_url={video_url} ratio={ratio} _uid={_uid} />
      </div>
      {caption && (
        <figcaption
          className={`w-full max-w-[960px] mx-auto mt-4 ${captionClass}`}
        >
          {caption}
        </figcaption>
      )}
    </figure>
  )
}

export default VideoFigure
